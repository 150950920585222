import { useQuery } from "@tanstack/react-query";
import { queryContants } from "../../constant";
import { getBanner, getNetStatus } from "../../apis/app";

export const useGetBannerQuery = () => {
  const accessToken = localStorage.getItem("accessToken");
  return useQuery({
    queryKey: [queryContants.GET_BANNER],
    queryFn: getBanner,
    enabled: !!accessToken,
  });
};

export const useGetNetStatus = (netId) => {
  const accessToken = localStorage.getItem("accessToken");
  return useQuery({
    queryKey: [queryContants.GET_NET_STATUS, netId],
    queryFn: () => getNetStatus(netId),
    enabled: !!accessToken,
  });
};
