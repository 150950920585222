import React, { useState } from "react";
import "swiper/swiper-bundle.css";
import { Button, Col, Image, Row } from "antd";
import iconSteam from "../assets/icons/home/Steam_icon_logo 1.png";
import iconGame from "../assets/icons/home/joystick 1.png";
import iconPriceTag from "../assets/icons/price-tag 1.png";
import { useAllGameQuery } from "../store/queries/game/game.query.js";
import { handleScrollVertical } from "../utils/scrollHandle.js";
import { LoadingIcon } from "../assets/icons/index.js";
import { debounce } from "lodash";
import { useWishlistMutation } from "../store/mutations/game/wishlist.mutate.js";
import { useNavigate } from "react-router-dom";
import { useGetNetStatus } from "../store/queries/app.queries.js";

const Chitiet = () => {
  const formatCurrency = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const net = localStorage.getItem("net");
  const netData = net ? JSON.parse(net) : "";
  const netId = netData.id ? netData.id : "1";

  const { data: netStatus } = useGetNetStatus(netId);

  const isGameFree = netStatus && !!netStatus.isBuy;

  const [searchFilterParams, setSearchFilterParams] = useState({
    search: "",
    sort: "trending",
  });
  const { mutateAsync } = useWishlistMutation();
  const {
    data: allData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useAllGameQuery(8, searchFilterParams);
  const navigate = useNavigate();

  const handleChangeSearchFilter = (filterParams) => {
    setSearchFilterParams((prev) => ({ ...prev, ...filterParams }));
  };

  const handleSearch = debounce((event) => {
    handleChangeSearchFilter({ search: event.target.value });
  }, 1000);

  const handleFilterChange = (sortOption) => {
    setSearchFilterParams((prevParams) => ({
      ...prevParams,
      sort: sortOption,
    }));
  };

  const handleToggleWishlist = (item) => {
    mutateAsync({ steamId: item.id, isWishlist: !item.isWishlist });
  };

  const handleNavigation = (link) => {
    navigate(link);
  };

  const truncateSteamText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  // const handleLog = ()=> {
  //   window.electron.sendToMain("log");
  // }
  // const [mes, setMes] = useState('')

  // if(window.electron){
  //   window.electron.receiveFromMain(
  //     "logs", (data) => { console.log('logs', data);
  //       setMes(data?.steam_path)
  //      })
  // }

  return (
    <div
      className="h-full overflow-y-auto pt-[92px]"
      onScroll={(e) =>
        handleScrollVertical(e, fetchNextPage, hasNextPage, isFetchingNextPage)
      }
    >
      <style
        dangerouslySetInnerHTML={{
          __html: "\n  .shifted {\n  margin-left: auto;\n}\n\n    ",
        }}
      />
      <section className="nft pt-4">
        <div className="confirmation-loader-2 overlay-2" />
        <div className="custom--container">
          {/* <button onClick={handleLog}>oke checked</button>
        <span>{mes}</span> */}
          <div className="nft__wrapper">
            <div className="fixed z-[1000] p-1">
              <div className="nft-sidebar relative md:max-w-[320px] lg:max-w-[390px] sm:ml-6 lg:ml-0 h-auto overflow-y-auto z-[9]">
                <div className="nft-sidebar__close">
                  <div className="nft-sidebar__close-inner">
                    <h5 className="text">Lọc</h5>
                    <button type="button" className="icon">
                      <i className="las la-times" />
                    </button>
                  </div>
                </div>
                {/* Search section */}
                <div className="accordion custom--accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <span className="text-2xl text-gray-200 pb-2 font-semibold">
                        Tìm kiếm
                      </span>
                    </h2>
                    <hr className="border-gray-600" />
                    <div className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="form">
                          <input type="hidden" name="name" defaultValue="" />
                          <div className="row gy-3">
                            <div className="col-12">
                              <input
                                type="text"
                                className="rounded-lg text-white bg-[#242333] border-[#565656] border w-full p-3"
                                placeholder="Tên game..."
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Filter section */}
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <span className="text-2xl text-gray-200 py-2 font-semibold">
                        Bộ lọc tìm kiếm
                      </span>
                    </h2>
                    <hr className="border-gray-600 mb-8" />
                    <div className="accordion-collapse">
                      <div className="rounded-2xl bg-[#242333] border border-[#242333] p-2">
                        {[
                          { label: "Xu hướng", value: "trending" },
                          { label: "Thuê nhiều nhất", value: "most_rent" },
                          { label: "Mới ra mắt", value: "new" },
                          { label: "Game miễn phí", value: "free" },
                        ].map((filter, index, array) => (
                          <div
                            key={index}
                            className={`flex items-center justify-between py-2 mx-6 cursor-pointer 
                              transition-opacity duration-300 ${
                                index < array.length - 1
                                  ? "border-b border-gray-600"
                                  : ""
                              } ${
                              searchFilterParams.sort === filter.value
                                ? "opacity-100"
                                : "opacity-50 hover:opacity-100"
                            }`}
                            onClick={() =>
                              handleFilterChange(filter.value.toLowerCase())
                            }
                          >
                            <span className="text-white p-2">
                              {filter.label}
                            </span>
                            <input
                              type="radio"
                              className="form-radio h-5 w-5 text-red-600 p-2 rounded-full"
                              checked={searchFilterParams.sort === filter.value}
                              readOnly
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[calc(100%-385px)] sm:pl-[0px] lg:pl-[30px] position-relative explore-nft ml-auto mt-[42px]">
              <div className="overlay-2" id="overlay2" />
              {/* Game list */}
              {isLoading ? (
                <Col
                  className="flex justify-center items-center h-[50vh]"
                  span={24}
                >
                  <img className="spinner" src={LoadingIcon} alt="Loading..." />
                </Col>
              ) : (
                <Row
                  gutter={[16, 16]}
                  justify="start"
                  className="sm:!-ml-[60px] md:!-ml-[30px] lg:!-ml-[8px]"
                >
                  {allData?.map((item) => (
                    <Col
                      xl={6}
                      lg={8}
                      sm={12}
                      xs={24}
                      key={item.id}
                      className="sm:-mb-[5px] md:mb-[10px] lg:mb-[28px]"
                    >
                      <div className="relative bg-radiant-gradient md:min-h-[300px] lg:h-full sm:w-[205px] md:w-[225px] lg:w-[300px] rounded-lg">
                        <div className="relative w-full sm:h-36 md:h-36 lg:h-44 overflow-hidden rounded-tl-lg rounded-tr-lg">
                          <div
                            onClick={() => {
                              handleNavigation(`/xemgame/${item.id}`);
                            }}
                            className="w-full h-full cursor-pointer"
                          >
                            <img
                              src={item.images[0]}
                              alt=""
                              className="object-cover w-full h-full transform transition-transform duration-300 hover:scale-110"
                            />
                          </div>
                        </div>
                        <div className="sm:p-2 md:p-4 p-5">
                          <div className="flex flex-wrap justify-between items-start gap-1.25 pb-3 border-b border-white/50">
                            <div className="flex items-center">
                              <div className="sm:sm:h-7 sm:w-7 md:h-8 md:w-8 lg:h-10 lg:w-10 flex items-center">
                                <Image src={iconSteam} alt="" preview={false} />
                              </div>
                              <div className="sm:pl-1 pl-3">
                                <p className="text-white sm:text-xs md:text-sm lg:text-base">
                                  Nền tảng
                                </p>
                                <span className="sm:text-xs md:text-sm lg:text-base">
                                  Steam
                                </span>
                              </div>
                            </div>
                            <div className="flex items-center">
                              <div className="sm:h-7 sm:w-7 md:h-8 md:w-8 lg:h-10 lg:w-10 flex items-center">
                                <Image src={iconGame} alt="" preview={false} />
                              </div>
                              <div className="sm:pl-1 pl-3">
                                <p className="text-white sm:text-xs md:text-sm lg:text-base">
                                  Chế độ
                                </p>
                                <span className="sm:text-xs md:text-sm lg:text-base">
                                  {truncateSteamText(item.chedo, 1)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 flex items-center">
                            <div className="sm:min-h-10 md:min-h-14 min-h-16">
                              <h4 className="truncate-text sm:text-[15px] md:text-xl lg:text-2xl">
                                <div
                                  onClick={() =>
                                    handleNavigation(`/xemgame/${item.id}`)
                                  }
                                  className="cursor-pointer hover:text-blue-400 line-clamp-2"
                                >
                                  {item.tengame}
                                </div>
                              </h4>
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div></div>
                            <div>
                              <Button
                                onClick={() => handleToggleWishlist(item)}
                                size="small"
                                className={`rounded-3xl text-base ${
                                  item.isWishlist
                                    ? "text-red-500 bg-blue-950"
                                    : "bg-blue-950 text-white"
                                }`}
                                data-id={item.id}
                                icon={<i className="fas fa-heart" />}
                              >
                                <span className="text-white wishlistCount sm:text-sm md:text-md lg:text-lg">
                                  {item.numberWishlist ?? 0}
                                </span>
                              </Button>
                            </div>
                          </div>
                          <div className="flex justify-between items-center pt-2">
                            <div className="flex items-center">
                              <div className="sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 flex items-center">
                                <Image
                                  src={iconPriceTag}
                                  alt=""
                                  preview={false}
                                />
                              </div>
                              <span className="text-base text-white font-semibold sm:text-sm md:text-md lg:text-lg">
                                Giá thuê
                              </span>
                            </div>
                            <div className="">
                              <span className="text-base font-semibold text-[#7ED0FF] sm:text-sm md:text-md lg:text-lg">
                                {isGameFree ? 0 : formatCurrency(item.giathue)}
                                đ/giờ
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                  {hasNextPage && (
                    <Col className="!mt-9 !mb-14" span={24}>
                      <img
                        className="spinner mx-auto"
                        src={LoadingIcon}
                        alt="spin icon"
                      />
                    </Col>
                  )}
                </Row>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Chitiet;
