import "./App.css";
import React, { useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import {
  TrangChu,
  Login,
  Register,
  AutoLoginSteam,
  ChiTiet,
  XemGame,
  KhamPha,
  QuanLyThue,
  AccountPage,
  PaymentPage,
} from "./pages";
import Layout from "./components/Layout/Layout";
import Verify from "./pages/Verify";
import VerifyApp from "./pages/VerifyApp";
import { socket } from "./helpers/socket.helper";
import Swal from "sweetalert2";
import { getRockstarOTP } from "./apis/game/game";
import { isArray, isEmpty } from "lodash";
import SuccessPage from "./pages/SuccessPage";
import { StateContext } from "./context/reducer";
import { electronConstants } from "./constant";
import { message } from "antd";

export default function App() {
  const { state } = useContext(StateContext);
  const { user } = state;

  useEffect(() => {
    if (user && user.timesLeft) {
      const timerId = setTimeout(() => {
        if (user.rentStatus && window.electron.sendToMain) {
          window.electron.sendToMain(electronConstants.LOG_OUT_STEAM);
        }
      }, user.timesLeft * 1000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [user]);

  useEffect(() => {
    socket.on("login_status", async (res) => {
      if (!res.isLogin) {
        const result = await Swal.fire({
          title: "Đăng nhập không thành công?",
          text: res.message,
          icon: "error",
          confirmButtonText: "Xác Nhận",
        });
        if (result.isConfirmed) {
          localStorage.removeItem("accessToken");
          window.electron.sendToMain &&
            window.electron.sendToMain("logout-user");
        } else {
          setTimeout(() => {
            localStorage.removeItem("accessToken");
            window.electron.sendToMain &&
              window.electron.sendToMain("logout-user");
          }, 5000);
        }
      }
    });
  }, [socket]);

  useEffect(() => {
    if (window?.electron?.receiveFromMain) {
      window.electron.receiveFromMain("sendOTPfromEmail", async (data) => {
        let retry = 0;
        let success = false;

      const fetchOTP = async () => {
        try {
          const res = await getRockstarOTP(data?.email);
          if (!isEmpty(res.data)) {
            console.log('show data', isArray(res.data) ? res.data[res.data.length - 1] : res.data);
            window.electron.sendToMain("getOTPfromemail",isArray(res.data) ? res.data[res.data.length - 1] : res.data);
            success = true; // Thành công, không cần retry nữa
          } else {
            retry++;
            setTimeout(fetchOTP, 1000);
          }
        } catch (err) {
          retry++;
          console.log(`Lỗi khi lấy OTP từ hệ thống. Lần thử thứ ${retry}`, err);
          if (retry < 5 && !success) {
            setTimeout(fetchOTP, 1000); // Gọi lại sau 1 giây nếu chưa thành công
          } else if(retry === 5) {
            message.error(err.message)
          }
        }}

        if (data?.email) {
          fetchOTP(); // Bắt đầu gọi OTP
        }
        
      });
    }
  }, [])

  return (<>
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<TrangChu />} />
          <Route path="/steam" element={<AutoLoginSteam />} />
          <Route path="/chitiet" element={<ChiTiet />} />
          <Route path="/xemgame/:id" element={<XemGame />} />
          <Route path="/khampha" element={<KhamPha />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/quanlythue" element={<QuanLyThue />} />
          <Route path="/account/:tab" element={<AccountPage />} />
        </Route>
        {/* {window.electron && (
          <> */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify/:email" element={<Verify />} />
        <Route path="/success" element={<SuccessPage />} />
        {/* </>
        )} */}
        <Route path="/forgot-password" element={<VerifyApp />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}
