import React, { useState } from "react";
import iconMomo from "../assets/icons/payment/MoMo.png";
// import iconVNpay from "../assets/icons/payment/VNpay.png";
import iconATM from "../assets/icons/payment/ATM.png";
import iconPayment from "../assets/icons/payment/money 1.png";

import MomoQR from "../assets/images/MomoQR.svg";
import BankingQR from "../assets/images/BankingQR.svg";
import { Image } from "antd";
// import { useCreatePaymentMutation } from "../store/mutations/payment/payment.mutate";
// import { StateContext } from "../context/reducer";
import {
  PaymentMethodCard,
  // PaymentInput,
  PaymentMethodForm,
} from "../components/Payment";
import PopupError from "../components/PopupConfirm/PopupError";

const PaymentPage = () => {
  // const { mutateAsync: createPaymentMutation } = useCreatePaymentMutation();
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
  // const [errorPopupMessage, setErrorPopupMessage] = useState("");
  // const [amount, setAmount] = useState("");
  // const {
  //   state: { user },
  // } = useContext(StateContext);

  const handleMethodClick = (method) => {
    setSelectedMethod(selectedMethod === method ? null : method);
    // setAmount("");
  };

  // const handleAmountChange = (e) => {
  //   setAmount(e.target.value);
  // };

  // const handleSendToMain = (action, data) => {
  //   if (window.electron.sendToMain) {
  //     window.electron.sendToMain(action, data);
  //   }
  // };

  // const handleConfirmPaymentClick = async () => {
  //   if (selectedMethod === "VNPay" && amount) {
  //     try {
  //       if (parseInt(amount, 10) < 50000) {
  //         setIsErrorPopupVisible(true);
  //         setErrorPopupMessage("Số tiền nạp tối thiểu là 20,000 VND");
  //         return;
  //       }
  //       const paymentData = {
  //         amount: parseInt(amount, 10),
  //         locale: "vn",
  //         orderInfo: "thanh toan",
  //         orderType: "other",
  //         userId: user.id,
  //         netId: 2,
  //       };
  //       const res = await createPaymentMutation(paymentData);

  //       if (res?.vnpUrl) {
  //         handleSendToMain("openExternalLink", res.vnpUrl);
  //       } else {
  //         setIsErrorPopupVisible(true);
  //         setErrorPopupMessage(
  //           "Đã có lỗi xảy ra, liên hệ hỗ trợ để được giúp đỡ."
  //         );
  //       }
  //     } catch (error) {
  //       setIsErrorPopupVisible(true);
  //       setErrorPopupMessage(
  //         "Đã có lỗi xảy ra, liên hệ hỗ trợ để được giúp đỡ."
  //       );
  //     }
  //   }
  // };

  return (
    <div className="flex justify-center h-full overflow-y-auto pt-[92px]">
      <div className="mt-10 w-[965px] h-[802px] overflow-auto bg-[#242333] rounded-lg p-6">
        <div className="flex justify-center items-center">
          <div className="h-[65px] w-[65px] mr-[12px]">
            <Image src={iconPayment} alt="Payment" preview={false} />
          </div>
          <h2 className="text-2xl font-bold pt-2 text-white">Nạp tiền</h2>
        </div>
        <div className="mt-6 space-y-4">
          {/* <PaymentMethodCard
            method="Momo"
            icon={iconMomo}
            title="Thanh toán Momo quét mã QR"
            description="Nạp tiền tự động qua MoMo, hoàn thành tức thì, phí giao dịch 0%"
            isSelected={selectedMethod === "Momo"}
            onClick={() => handleMethodClick("Momo")}
          >
            <PaymentInput
              amount={amount}
              onAmountChange={handleAmountChange}
              onConfirmClick={handleConfirmPaymentClick}
            />
          </PaymentMethodCard> */}
          <PaymentMethodCard
            method="MomoManual"
            icon={iconMomo}
            title="Nạp tiền qua MoMo 16/7 (số tiền nạp tối thiểu 50.000 vnd)"
            description="Chuyển khoản MoMo online, xử lý 5p->10p (7h -> 23h hàng ngày)"
            isSelected={selectedMethod === "MomoManual"}
            onClick={() => handleMethodClick("MomoManual")}
          >
            <PaymentMethodForm AppPayment="Momo" QR={MomoQR} />
          </PaymentMethodCard>
          <PaymentMethodCard
            method="BankingManual"
            icon={iconATM}
            title="Nạp tiền qua ngân hàng 16/7 (số tiền nạp tối thiểu 50.000 vnd)"
            description="Chuyển khoản Ngân hàng online, xử lý 5p->10p (7h -> 23h hàng ngày)"
            isSelected={selectedMethod === "BankingManual"}
            onClick={() => handleMethodClick("BankingManual")}
          >
            <PaymentMethodForm AppPayment="Ngân Hàng" QR={BankingQR} />
          </PaymentMethodCard>
          {/* <PaymentMethodCard
            method="VNPay"
            icon={iconVNpay}
            title="Thanh toán VNPAY quét mã QR"
            description="Quét mã nạp tiền tự động qua ứng dụng ngân hàng (Mobile Banking), phí GD 0%"
            isSelected={selectedMethod === "VNPay"}
            onClick={() => handleMethodClick("VNPay")}
          >
            <PaymentInput
              amount={amount}
              onAmountChange={handleAmountChange}
              onConfirmClick={handleConfirmPaymentClick}
            />
          </PaymentMethodCard> */}

          <div className="bg-[#3C3C56] text-white py-4 px-6 rounded-xl opacity-50 cursor-not-allowed">
            <div className="flex items-center">
              <div>
                <div>Thanh toán VN quét mã QR</div>
                <div>(Coming Soon)</div>
              </div>
            </div>
          </div>
          <div className="bg-[#3C3C56] text-white py-4 px-6 rounded-xl opacity-50 cursor-not-allowed">
            <div className="flex items-center">
              <div>
                <div>Thanh toán Momo quét mã QR</div>
                <div>(Coming Soon)</div>
              </div>
            </div>
          </div>
          <div className="bg-[#3C3C56] text-white py-4 px-6 rounded-xl opacity-50 cursor-not-allowed">
            <div className="flex items-center">
              <div>
                <div>Chuyển khoản ngân hàng 24/7</div>
                <div>(Coming Soon)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupError
        visible={isErrorPopupVisible}
        onClose={() => setIsErrorPopupVisible(false)}
        title={"Cảnh báo!"}
        // TODO: đổi lại thành biến sử dụng sau khi mở lại VNPAY
        content={"errorPopupMessage"}
        buttons={[
          {
            label: "Đóng",
            className:
              "px-4 py-2 text-sm font-medium text-white bg-[#FF6D6D] border border-gray-300 rounded-[30px] shadow-sm opacity-70 hover:opacity-100",
            onClick: () => setIsErrorPopupVisible(false),
          },
        ]}
      />
    </div>
  );
};

export default PaymentPage;
