import axiosServer from "../axios/axiosServer";
import APIs from "../constant/endpoint";

export const getBanner = async () => {
  const res = await axiosServer.get(APIs.getBanner);
  return new Promise((resolve) => setTimeout(() => resolve(res.data), 100));
};

export const getNetStatus = async (netId) => {
  const res = await axiosServer.get(APIs.getNetStatus, { params: { netId } });
  return new Promise((resolve) => setTimeout(() => resolve(res.data), 100));
};
